<template>
  <div class="filter-group md:border-none md:m-1 md:mx-1 flex-1 text-14px">
    <div
      @click="open = !open"
      :class="open ? 'bg-gray-100 md:bg-transparent' : ''"
      class="header flex items-center p-3 hover:bg-gray-100 border-b cursor-pointer md:cursor-auto md:border-b-0 md:hover:bg-transparent"
      style="min-width: 0"
    >
      <div class="flex-1">
        <div style="white-space: nowrap">
          <strong>{{ name }}</strong>
        </div>
        <div
          class=" md:hidden text-sm text-gray-500 truncate overflow-ellipsis overflow-hidden"
          style="white-space:nowrap"
        >
          {{ summary }}
        </div>
      </div>
      <span class="md:hidden float-right mt-1 text-gray-400">
        <svg
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </span>
      <!-- <i class="md:hidden fas fa-chevron-down float-right mt-1 text-gray-400"></i> -->
    </div>

    <div :class="open ? '' : 'hidden md:block'" class="p-2 ">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    summary: {
      type: String,
      default: '',
    },
    activeFilterName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    }
  },
  watch: {
    open(open) {
      if (open) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },
    activeFilterName(activeFilterName) {
      if (activeFilterName !== this.name) {
        this.open = false
      } else {
        this.open = true
      }
    },
  },
}
</script>
