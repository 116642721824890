const getSubIndexDataMixin = {
  data: function() {
    return {
      indexData: [],
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      // indexDataEndpoint: ""
    }
  },
  computed: {
    indexDataParentId() {
      return this.$route.params.id
    },
    indexDataEndpoint() {
      return ''
    },
    // indexIsFunction() {
    //   return this.indexDataEndpoint && {}.toString.call(this.indexDataEndpoint) === '[object Function]';
    // },
    // resolvedIndex() {
    //   return this.indexIsFunction ? this.indexDataEndpoint(this.indexDataParentId) : this.indexDataEndpoint
    // }
  },
  watch: {
    // watch queryString & re-fetch
    getQueryString: async function(updatedQueryString) {
      await this.getIndexData(updatedQueryString)
    },
  },
  async created() {
    // console.log(this.getQueryString);
    this.getIndexData(this.getQueryString)
  },
  methods: {
    getIndexData: async function(queryString) {
      this.isLoading = true
      // todo: remove log
      // console.log(taxApi.index + "indexData");
      try {
        const response = await this.$http.get(
          this.indexDataEndpoint + queryString
        )
        this.indexData = response.data.data
        this.indexMetaData = response.data.meta
        // todo: remove log
      } catch (error) {
        // this.errors.push(error);
        // todo: remove log
        console.log(error)
      }
      this.isLoading = false
    },
  },
}

module.exports = getSubIndexDataMixin
