export const AgentUserConfig = {
  api: {
    // get
    index: '/dashboard/agents/',
    // get
    topupHistory: (id = 'uuid') => {
      return `/dashboard/agents/${id}/topup-history/`
    },
    // get
    incomingHistory: (id = 'uuid') => {
      return `/dashboard/agents/${id}/incoming-history/`
    },
    // post
    balanceTransfer: (id = 'uuid') => {
      return `/dashboard/agents/${id}/transfer-balance/`
    },
  },
  events: {
    name: 'agent',
    // refresh-index-data
    refresh: `rid-agent`,
    // slideover-right
    sorId: 'agent',
    sorOpen: 'sor-open-agent',
    sorClose: 'sor-close-agent',
    sorToggle: 'sor-toggle-agent',
    // editing-data
    editingData: 'edit-agent-data',
    // viewing-data
    viewingData: 'view-agent-data',
  },
}
